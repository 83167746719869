import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    charityOfApp: {
      name: 'Centre of ioCharity'
    },
    promisedCharity: null,
    // for easy fallback to iocharity(default) when needed
    iocharity: {
      name: 'Centre of ioCharity'
    },
    pwa: {
      appinfo: null,
      photos: null,
      appleTouchIcon: null
    },
    contributors: {
      registeredCollaborators: [],
      numberOfRegisteredCharities: null
    }
  },
  getters: {
    barTitle (state) {
      // console.log('Update App Bar title: ', state.charityOfApp.name)
      return state.charityOfApp.name
    },
    charityOfApp (state) {
      return state.charityOfApp
    },
    promisedCharity (state) {
      if (state.promisedCharity) {
        return state.promisedCharity
      } else {
        return new Promise((resolve) => resolve(state.iocharity))
      }
    },
    pwaAppInfo (state) {
      return state.pwa.appinfo
    },
    pwaPhotos (state) {
      return state.pwa.photos
    },

    appleTouchIcon (state) {
      return state.appleTouchIcon
    },
    registeredCollaborators (state) {
      return state.contributors.registeredCollaborators
    },
    numberOfregisteredCollaborator (state) {
      return state.contributors.numberOfregisteredCollaborators
    }
  },
  mutations: {
    setCharityOfApp (state, charity) {
      state.charityOfApp = charity
    },
    setCharityofAppPromise (state, promisedCharity) {
      state.promisedCharity = promisedCharity
    },
    setRegisteredCollaborators (state, registered) {
      state.contributors.registeredCollaborators = registered
    },
    setNumberOfRegisteredCollaborators (state, count) {
      state.contributors.numberOfRegisteredCharities = count
    }
  },
  actions: {
    /*
     * This function will first send a backend request
     * to check if the current url first segment is belonging to a charity group.
     *  if yes, update and cache the charity of the App. If not, fall back to local cache or default iocharity
     *
     *  If either the charity is not found or network fails, then the default iocharity data is used.
     *  A backend request is always sent first as it ensures that the
     *    user will always get the updated charity of the App
     */
    initCharityOfTheApp (context, firstSegmentOfPath) {
      const promise = new Promise((resolve, reject) => {
        if (firstSegmentOfPath !== 'iocharity') {
          return Vue.$publicApi.get('/account/searchexactemail', { params: { email: firstSegmentOfPath } })
            .then((response) => {
              const charity = response.data
              if (charity.group === 'charity') {
                context.commit('setCharityOfApp', charity)
                localStorage.setItem(`${firstSegmentOfPath}-charity`, JSON.stringify(charity))
                // console.log(`Successfully receive API request of Charity of the App: ${charity.email}`)
                resolve(charity)
              } else {
                reject(new Error())
              }
            }).catch(() => {
              console.error('Charity of the App is not found. Fallback to cache or Default iocharity centre')
              const charity = JSON.parse(localStorage.getItem(`${firstSegmentOfPath}-charity`))
              if (charity !== null && 'email' in charity) {
                context.commit('setCharityOfApp', charity)
              } else {
                context.commit('setCharityOfApp', context.state.iocharity)
              }
              reject(new Error('Fails to initial the charity'))
            })
        } else {
          context.commit('setCharityOfApp', context.state.iocharity)
          resolve(context.state.iocharity)
        }
      })
      context.commit('setCharityofAppPromise', promise)
      return promise
    },
    async initPwaDetails (context, firstSegmentOfPath) {
      context.dispatch('setAppleTouchIcon', firstSegmentOfPath)
      Vue.$publicApi.get('/pwa/pwaphotos', { params: { charity: firstSegmentOfPath } })
        .then((response) => {
          const photos = response.data
          context.state.pwa.photos = photos
          // console.log('Received PWA photos')
        }).catch((err) => {
          console.error(`${firstSegmentOfPath} does not set photos for PWA or network fails: `, err.response.status)
          context.state.pwa.photos = []
        })
      return Vue.$originApi.get(`/${firstSegmentOfPath}/manifest`)
        .then((response) => {
          const manifest = response.data
          context.state.pwa.appinfo = {}
          context.state.pwa.appinfo.name = manifest.name
          context.state.pwa.appinfo.short_name = manifest.short_namee
          context.state.pwa.appinfo.description = manifest.description
          context.state.pwa.appinfo.active = true
          context.state.pwa.appinfo.icons = manifest.icons
          // console.log('Received PWA App Info')
          return manifest
        }).catch((err) => {
          console.error(`${firstSegmentOfPath} set the PWA to be inactive or the network fails`, err)
          context.state.pwa.appinfo = { active: false }
          throw err
        })
    },
    setAppleTouchIcon (context, firstSegmentOfPath) {
      Vue.$originApi.get(`/${firstSegmentOfPath}/apple-touch-icon`)
        .then((response) => {
          // console.log(`Received PWA apple-touch-icon ${response.data}`)
          context.state.pwa.appleTouchIcon = response.data
          document.getElementById('apple-touch-icon').href = response.data
          // console.log(context.state.pwa)
        }).catch((err) => {
          console.error(`${firstSegmentOfPath} does not set apple-touch-icon for the PWA or network fails to retrieve: `,
            err.response.status)
        })
    },
    requestRegisteredContributors (context) {
      const loginConfirm = context.rootGetters['userStore/loginConfirm']
      if (loginConfirm) {
        Vue.$privateApi.get('/project/charity/subscriptions').then((response) => {
          context.commit('setRegisteredCollaborators', response.data.registered)
          context.commit('setNumberOfRegisteredCollaborators', response.data.count)
          // console.log(response.data)
          return response.data
        }).catch(() => {
          throw context.state.contributors.registeredCollaborators
        })
      }
    }
  }
}
