import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    appUserLoggedIn: null,
    registeredCharities: [],
    numberOfRegisteredCharities: null
  },
  mutations: {
    setRegisteredCharities (state, registered) {
      state.registeredCharities = registered
    },
    setNumberOfRegisteredCharities (state, count) {
      state.numberOfRegisteredCharities = count
    }
  },
  getters: {
    loginConfirm (state) {
      return state.appUserLoggedIn
    },
    registeredCharities (state) {
      return state.registeredCharities
    },
    numberOfRegisteredCharities (state) {
      return state.numberOfRegisteredCharities
    }
  },
  actions: {
    /**
     * Vue is instantiated before Keycloak
     * call this method to update the final login status after Keycloak
     *  response is recieved
     */
    setAppUserLoggedIn (context, login) {
      // console.log('Received User JWT, update drawers and content pages to reflect this')
      context.state.appUserLoggedIn = login
    },
    requestRegisteredCharities (context) {
      if (context.state.appUserLoggedIn) {
        return Vue.$privateApi.get('/project/subscriptions').then((response) => {
          const registeredCharities = response.data.registered
          context.commit('setRegisteredCharities', registeredCharities)
          context.commit('setNumberOfRegisteredCharities', response.data.count)
          return response.data
        }).catch((err) => {
          throw err
        })
      }
    },
    joinCharity (context, charity) {
      const charitySub = { charitySub: charity.userId }
      return Vue.$privateApi.post('/project/subscription', charitySub)
        .then(() => {
          context.state.registeredCharities.push(charity)
          // console.log('join charity,', context.state.registeredCharities)
          return true
        }).catch((err) => {
          throw err
        })
    },
    leaveCharity (context, charity) {
      const charitySub = { charitySub: charity.userId }
      return Vue.$privateApi.put('/project/subscription/delete', charitySub)
        .then(() => {
          const index = context.state.registeredCharities.findIndex((registeredCharity) => {
            return charity.userId === registeredCharity.userId
          })
          context.state.registeredCharities.splice(index, 1)
          // console.log('leave charity,', context.state.registeredCharities)
        }).catch((err) => {
          throw err
        })
    }
  }
}
