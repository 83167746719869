
/**
 * This method will return the valid scope for service worker registration depending on the current path
 * check if the path = ''/${charityEmail}/*'
 * split() will splits the path into ['','${charityEmail}', ...] if the above statement is true
 * if the condition is true, scope of sw.js should be /${charityEmail} and the manifest path is /{charityEmail}/webmanifest
 */
async function registerPWAAndManifest () {
  const paths = window.location.pathname.split('/')
  setManifestUrl(paths)
  configPWAEventListeners()
  if ('serviceWorker' in navigator) {
    const scopeJson = formScope(paths)

    // Use the window load event to keep the page load performance
    window.addEventListener('load', (registration) => {
      navigator.serviceWorker.register('/service-worker.js', scopeJson)
        .then((registration) => {
          console.log('service worker has been registered: ') //, registration)
        }).catch((err) => {
          console.error('service worker has been failed to be registered: ', err)
        })
    })
  } else {
    console.error('PWA is not applicable in this browser!')
  }
}

function setManifestUrl (paths) {
  if (paths.length > 1) {
    // console.log(`get manifest from /${paths[1]}/manifest`)
    document.getElementById('pwamanifest').href = `/${paths[1]}/manifest`
  } else {
    console.error('No manifest found')
  }
}

// Guided by Documentation Reference: https://web.dev/customize-install/
function configPWAEventListeners () {
  // install Event
  window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault()
    window.installEvent = event
    // console.log('Save beforeinstallprompt event.')
  })
}

function formScope (paths) {
  const scopeJson = { scope: '/' }
  // all routes defined are ensured to have at least 1 path segment by using vue-router's redirect
  if (paths.length >= 2) {
    scopeJson.scope = `/${paths[1]}`
  }
  return scopeJson
}

export default { registerPWAAndManifest }
