import Vue from 'vue'
import axios from 'axios'
/**
  * Reference: https://developers.google.com/maps/documentation/javascript/geocoding?authuser=1
  * Reference: https://developers.google.com/maps/documentation/geocoding/geocoding-strategies#client
  * This API key of Google Map API for Geocoding is
  *  safe to be used at client side. Security is set at Cloud & this reduces server loads
  *  Only the domain of the web app is allowed
*/

const geocoderApi = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/public/account/geolocation`
})

const GeoApiPlugin = {
  install (Vue, options) {
    Vue.$geocoder = geocoderApi
  }
}

Vue.use(GeoApiPlugin)
