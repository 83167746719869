import Keycloak from 'keycloak-js'
import Vue from 'vue'
/**
 * Setup keycloak plugin
 */

const initConfig = {
  url: process.env.VUE_APP_AUTH_BASE_URL,
  realm: process.env.VUE_APP_AUTH_REALM_NAME,
  clientId: process.env.VUE_APP_AUTH_CLIENT_ID
}

const keycloak = new Keycloak(initConfig)
function init () {
  /**
   * when token is expired, this is called to refresh the token
   * if refresh fails, then user is redirected to login page
   */
  keycloak.onTokenExpired = function () {
    keycloak.updateToken().then((isRefreshed) => {
      // console.log(`Refresh User Acess Token: ${isRefreshed}`)
    }).catch((err) => {
      console.error(`cannot refresh token: ${err}`)
      keycloak.clearToken()
    })
  }

  /**
   * Login page is loaded from auth server keycloak since  authorization flow is used for security requirement.
   * To satify user login experience of the app in PWA mode, the login page should be openned in a new window tab, so that
   *  the user can choose to close the current window tab if the user does not want to perform login and the parent window tab,
   *  where the web app being opened is unaffected.
   * On successful authentication, user will be redirected back to the web app's page where the login is triggered.
   * Hence, when the app is rendered, the app should first check that,
   *  if the user is authenticated and itself is a child window,
   *  then it will need to send an event to its parent to tell this.
   * See Login method under AppNavigation.vue. When the parent receives the event,
   *  it closes the child and reload itself
   * If it is not a child window tab, then it should be a parent tab and no event needs to be sent
   */
  keycloak.onAuthSuccess = function () {
    if (window.opener) {
      const message = { auth: true }
      window.opener.postMessage(message, window.location.href)
    }
  }
}

init()
/**
 * Set Keycloak as a plugin
 */
const keycloakPlugin = {
  install (Vue, options) {
    Vue.$keycloak = keycloak
  }
}

Vue.use(keycloakPlugin)
