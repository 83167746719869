import Vue from 'vue'
import Vuex from 'vuex'
import charityStore from './modules/charitystore.js'
import giverStore from './modules/giverstore.js'
import appStore from './modules/appstore.js'
import userStore from './modules/userstore.js'
import donationStore from './modules/donationstore.js'
import projectStore from './modules/projectstore.js'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    appStore,
    charityStore,
    giverStore,
    userStore,
    projectStore,
    donationStore
  }
})
