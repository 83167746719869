<template>
  <div>
    <v-list  v-if="isDataAvailable" >
      <v-list-item>
        <v-list-item-avatar :size = 'pictureSize' class="mx-auto" :color="avatarColor">
          <v-img  v-if="userHasPicture" :src="user.picture"></v-img>
          <h1 v-else class="white--text">{{ shortName }}</h1>
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title > <span class="d-flex justify-center">
            {{ user.name }}</span></v-list-item-title>
          <v-list-item-subtitle>
            <span class="d-flex justify-center">
              {{ user.email }}</span>
            <span class="d-flex justify-center mt-2">
              {{ id }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-else>
        <v-list-item>
            <v-list-item-avatar class="mx-auto">
                <v-icon large> mdi-account-circle </v-icon>
            </v-list-item-avatar>
        </v-list-item>
    </v-list>
  </div>
</template>

<script>
/**
 * A profile component that display email and full name (firstName needs).
 * If user picture is available, user picture is rendered. If not, a fake avatar with user shortname is used
 * If both name and email are not available, then only a avatar icon image is displayed.
 * For example, when this is used to display the current user's info,
 *  then if the current user is not logged in, then a fake icon image is displayed instead
 */
export default {
  name: 'profile',
  props: { user: { default: function () { return {} } }, pictureSize: { default: '50' } },
  data () {
    return {
      isDataAvailable: false,
      group: ''
    }
  },
  computed: {
    userHasPicture () {
      if (this.user.picture) {
        return true
      } else {
        return false
      }
    },
    shortName () {
      const firstChar = this.user.firstName.charAt(0).toUpperCase()
      const lastChar = this.user.lastName.charAt(0).toUpperCase()
      // console.log(firstChar + lastChar)
      return firstChar + lastChar
    },
    avatarColor () {
      if (this.group.includes('giver')) {
        return this.$vuetify.theme.themes.light.giverAvatar
      } else {
        return this.$vuetify.theme.themes.light.charityAvatar
      }
    },
    id () {
      const front = this.user.sub.split('-')[0]
      return `ID: ${front}`
    }
  },
  created () {
    if (this.user && 'name' in this.user && 'email' in this.user) {
      this.isDataAvailable = true
    } else {
      this.isDataAvailable = false
    }
    if (this.isDataAvailable && this.user.groups.length > 0) {
      this.group = this.user.groups[0]
    } else {
      this.isDataAvailable = false
    }
  }
}
</script>
