/* eslint-disable no-undef */
import Vue from 'vue'

const script = document.createElement('script')
script.src = 'https://js.stripe.com/v3/'
document.head.appendChild(script)

const pubk = process.env.VUE_APP_STRIPE_PB

function generateStripeFor (accountId) {
  return Stripe(pubk, {
    stripeAccount: accountId
  })
}

const stripePlugin = {
  install (Vue, options) {
    Vue.$stripe = { generateStripeFor }
  }
}
Vue.use(stripePlugin)
