import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    givers: {},
    contributions: {},
    searchHistory: new Set()
  },
  mutations: {
    addSearchHistory (state, searchString) {
      state.searchHistory.add(searchString)
    },
    addContributions (state, data) {
      state.contributions[data.email] = data.contributions
    },
    addGivers (state, givers) {
      givers.forEach(giver => {
        state.givers[giver.email] = giver
      })
    }
  },
  actions: {
    /* return the charities from backend API
       * This function will cache the search history in a Set whenever the response is 200OK.
       * If the input is found inside the search history,
       * no backend search request is sent to avoid flooding the backend services
       */
    requestContributionsOf (context, email) {
      if (!context.state.searchHistory.has(email)) {
        return Vue.$publicApi.get('/project/contributions',
          {
            params: {
              contributoremail: email
            }
          })
          .then(response => {
            const contributions = response.data
            context.commit('addSearchHistory', email)
            const data = {
              email: email,
              contributions: contributions
            }
            context.commit('addContributions', data)
            return response.data
          }).catch(() => {
            throw Error()
          })
      } else {
        return context.state.contributions[email]
      }
    },
    searchExactGiver (context, giverExactEmail) {
      if (!context.state.searchHistory.has(giverExactEmail)) {
        return Vue.$publicApi('/account/searchexactemail',
          {
            params: {
              email: giverExactEmail
            }
          }).then((response) => {
          context.commit('addSearchHistory', giverExactEmail)
          context.commit('addGivers', [response.data])
          return response.data
        })
      } else {
        return context.state.givers[giverExactEmail]
      }
    },
    getGiverByEmail (context, giverEmail) {
      return context.state.givers[giverEmail]
    },
    containsGiver (context, content) {
      return content in context.state.givers
    }
  }
}
