<template>
  <v-app app>
    <v-snackbar v-model="snackbar.display" :color="snackbar.color"
        outlined timeout="5000" bottom>
      <span class="mx-auto">{{snackbar.message}}</span>
    </v-snackbar>
    <v-banner elevation="14" color="#0d0a0a"
        style="z-index:14;position:fixed;bottom:7%;left:10px">
      <v-alert text type="info" icon="mdi-cookie">
        {{cookieMessage}}
        <br>
        For more information, please reference to
        <a target="blank" :href="cookieLink">Stripe JS Doc.</a>
      </v-alert>
      <template v-slot:actions="action">
        <v-btn @click="action.dismiss">
          OK
        </v-btn>
      </template>
    </v-banner>
    <!--navigation menu at top and left-->
    <app-navigation ref="appNav"></app-navigation>
    <!--the content. See router/index.js for detials about default and rules for router views-->
    <v-main :key="mainKey">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import AppNavigation from '@/components/navigation/AppNavigation.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    AppNavigation
  },
  data () {
    return {
      cookieMessage: 'This is a UOL-CIS project Web App and only contains cookies from Stripe for secure online donation. ' +
       'No other information about the anonymous visitor is stored.',
      cookieLink: 'https://stripe.com/docs/js/appendix/cookies',
      snackbar: {
        message: '',
        display: false,
        color: 'primary'
      },
      mainKey: 1
    }
  },
  methods: {
    ...mapActions('appStore', ['initCharityOfTheApp']),
    ...mapGetters('userStore', ['loginConfirm']),
    displayBar (message, color) {
      this.snackbar.message = message
      this.snackbar.display = true
      this.snackbar.color = color
    }
  },
  watch: {
    loginConfirm (newValue) {
      if (newValue !== null) {
        // trigger this to refresh v-main
        this.mainKey += 1
      }
    }
  },
  mounted () {
    window.addEventListener('offline', (event) => {
      this.displayBar('You lose your Internet connection!', 'error')
      // console.log('offline', event)
    })
    window.addEventListener('online', (event) => {
      this.displayBar('You get back your Internet connection', 'primary')
      // console.log('online', event
    })
  }
}
</script>
