<template>
  <div>
    <!--the bar at the top-->
    <v-app-bar app color="transparent" elevation=0>
      <v-icon @click="openDrawer" class ="mr-3" :large="!$vuetify.breakpoint.mobile">mdi-menu</v-icon>
      <v-toolbar-title>{{appTitle}}</v-toolbar-title>

    <v-row  justify='end'>
      <v-spacer></v-spacer>
      <span v-for="view in views" :key="view.icon">
        <v-tooltip bottom v-model="view.tooltipDisplay" color="primary" >
        <template v-slot:activator="input">
        <v-btn @click="navigate(view.navigate)" v-on="input.on" v-bind="input.attrs"
          :small="$vuetify.breakpoint.mobile" icon outlined rounded class="mr-2">
          <v-icon :color="view.color">{{view.icon}}</v-icon>
        </v-btn>
        </template>
        {{view.message}}
        </v-tooltip>
      </span>
    </v-row>
    </v-app-bar>
    <!--the drawer at the left-->
    <Drawer :isOpen.sync="drawer.isOpen" :key="appDrawerKey"></Drawer>
    <v-dialog v-model="dialog.open" :max-width="$vuetify.breakpoint.mobile?'82vw':'52vw'">
      <PwaDashboard></PwaDashboard>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import Drawer from '@/components/navigation/Drawer'
import PwaDashboard from '@/components/PwaDashboard'
import { mapGetters } from 'vuex'
export default {
  name: 'AppNavigation',
  components: {
    Drawer,
    PwaDashboard
  },
  data () {
    return {
      appDrawerKey: 0,
      dialog: {
        open: false
      },
      snackbar: {
        maessage: 'Text123',
        display: true,
        color: 'primary'
      },
      drawer: {
        isOpen: false
      },
      views: [
        {
          icon: 'mdi-heart',
          color: 'red',
          navigate: () => { this.dialog.open = true },
          tooltipDisplay: false,
          message: 'Install PWA'
        },
        {
          icon: 'mdi-magnify',
          color: 'secondary',
          navigate: () => {
            this.$router.push({ name: 'SearchView', params: { root: Vue.$pathConverter.getFirstSegment() } })
          },
          tooltipDisplay: false,
          message: 'Search'
        },
        {
          icon: 'mdi-reload',
          color: 'secondary',
          navigate: () => { window.location.reload() },
          tooltipDisplay: false,
          message: 'Reload Page'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('appStore', ['barTitle']),
    ...mapGetters('userStore', ['loginConfirm']),
    appTitle () {
      return this.barTitle
    }
  },
  methods: {
    openDrawer () {
      this.drawer.isOpen = true
    },
    navigate (navFunc) {
      navFunc()
    }
  },
  watch: {
    loginConfirm (newValue) {
      if (newValue !== null) {
        // trigger to refresh Drawer component
        this.drawer.isOpen = false
        this.appDrawerKey += 1
      }
    }
  },
  mounted () {
    window.addEventListener('beforeinstallprompt', () => {
      setTimeout(() => {
        this.views[0].message = 'Install APP HERE!'
        setTimeout(() => {
          this.views[0].tooltipDisplay = false
          this.views[0].message = 'Install PWA'
        }, 3000)
        this.views[0].tooltipDisplay = true
      }, 1000)
    })
  }
}
</script>
