import Vue from 'vue'
import store from '@/store'
const getFirstSegmentOf = function (path) {
  const segments = path.split('/')
  // get x from '/x/...'
  let segment = segments[1]
  // if 'x#...' or 'x?...' when url has anchor or query
  segment = segment.split(/\?|#/)[0]
  if (segment === undefined) {
    return null
  }
  return segment
}
const getFirstSegment = function () {
  const segment = getFirstSegmentOf(window.location.pathname)
  return segment
}

const getLastSegmentOf = function (path) {
  const segments = path.split('/')
  // get x from /.../x
  let segment = segments[segments.length - 1]
  // if 'x#...' or 'x?...' when url has anchor or query
  segment = segment.split(/\?|#/)[0]
  if (segment === undefined) {
    return null
  }
  return segment
}

const convertTabTitle = function () {
  const title = store.getters['appStore/barTitle']
  const webTitle = document.createElement('title')
  webTitle.innerHTML = title
  document.head.appendChild(webTitle)
}

const convertFavicon = function () {
  // const webFavicon = document.createElement('link')
  // webFavicon.rel = 'icon'
  // webFavicon.type = 'image/png'
  const url = store.getters['appStore/pwaAppInfo'].icons[1].src
  document.getElementById('fav').href = url
  // webFavicon.href = url
  // document.head.appendChild(webFavicon)
}

const pathConverterPlugin = {
  install (Vue, options) {
    Vue.$pathConverter = { getFirstSegment, getFirstSegmentOf, getLastSegmentOf, convertTabTitle, convertFavicon }
  }
}
Vue.use(pathConverterPlugin)
