import Vue from 'vue'
import axios from 'axios'

const originApi = axios.create({
  baseURL: window.location.origin
})
/**
 * create a public client for publci api access
 *  and a private client for private api access that needs JWT for authentication and authorization
 */
const publicApi = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/public`
})

const privateApi = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/private`
})

/**
 * An intercepter for axios client to insert bearer before sending out requests
 * @param {*} client
 */
async function oauthIntercepter (client) {
  client.headers.Authorization = `Bearer ${Vue.$keycloak.token}`
  return client
}

privateApi.interceptors.request.use(oauthIntercepter, function (error) { return Promise.reject(error) })

const originApiPlugin = {
  install (Vue, options) {
    Vue.$originApi = originApi
  }
}

const publicApiPlugin = {
  install (Vue, options) {
    Vue.$publicApi = publicApi
  }
}

const privateApiPlugin = {
  install (Vue, options) {
    Vue.$privateApi = privateApi
  }
}

Vue.use(originApiPlugin)
Vue.use(publicApiPlugin)
Vue.use(privateApiPlugin)
