import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    projects: {},
    currentProjectId: '',
    addedTask: null,
    newlyDeletedTask: null
  },
  getters: {
    listProjects (state) {
      return state.projects
    },
    currentProject (state) {
      if (Object.prototype.hasOwnProperty.call(state.projects, state.currentProjectId)) {
        return state.projects[state.currentProjectId]
      } else {
        return null
      }
    },
    currentTasks (state) {
      if (state.currentProjectId in state.projects &&
          'tasks' in state.projects[state.currentProjectId]) {
        return state.projects[state.currentProjectId].tasks
      } else {
        return null
      }
    },
    currentAddedTask (state) {
      return state.addedTask
    },
    newlyDeletedTask (state) {
      return state.newlyDeletedTask
    }
  },
  mutations: {
    setCurrentProjectId (state, projectId) {
      state.currentProjectId = projectId
    },
    setProjects (state, projects) {
      projects.forEach(project => {
        state.projects[project.projectId] = project
      })
    },
    addProject (state, project) {
      state.projects[project.projectId] = project
    },
    setTasks (state, tasks) {
      tasks.forEach((task) => {
        if (!('tasks' in state.projects[task.task.projectId])) {
          state.projects[task.task.projectId].tasks = {}
        }
        state.projects[task.task.projectId].tasks[task.task.taskId] = task
      })
    },
    addTask (state, task) {
      state.projects[task.task.projectId].tasks[task.task.taskId] = task
    },
    setAddedTask (state, task) {
      state.addedTask = task
    },
    setDeletedTask (state, task) {
      state.newlyDeletedTask = task
      delete state.projects[task.task.projectId].tasks[task.task.taskId]
    }
  },
  actions: {
    createProject (context, project) {
      project.charitysub = Vue.$keycloak.tokenParsed.userId
      return Vue.$privateApi.post('/project/charity/project', project,
        {
          headers: { 'Content-type': 'application/json' }
        }).then((response) => {
        // console.log(response.data)
        context.commit('addProject', response.data)
        return response
      }).catch((err) => {
        throw err.response
      })
    },
    /**
     * GET request project lists and use Getter to access them
     */
    async requestProjects (context) {
      const promisedCharity = context.rootGetters['appStore/promisedCharity']
      const charity = await promisedCharity
      // console.log(charity)
      if (charity && 'group' in charity &&
          charity.group === 'charity') {
        // console.log('requestProjects-----')
        return await Vue.$publicApi.get('/project/getprojects', {
          params: {
            charityemail: charity.email,
            charitysub: charity.userId
          }
        }).then((response) => {
          const data = response.data
          context.commit('setProjects', data)
          // console.log(data)
          return data
        }).catch((err) => {
          console.error(`Fails to retrieve projects of the app of ${charity.name}`, err)
          throw err
        })
      }
    },
    /**
     * Get the tasks of a project
     * Return and leaving async function to add executor details
     * Access the state via Getter
     */
    async requestTasksForCurrentProject (context) {
      const requestProject = async () => {
        // console.log('requestProjects')
        return await context.dispatch('requestProjects')
        //   .then((projects) => {
        //   return projects
        // }).catch((err) => {
        //   console.error('project store', err)
        //   throw err
        // })
      }
      let project = context.state.projects[context.state.currentProjectId]
      if (!project) {
        await requestProject().catch((err) => {
          console.error('project store, requestProject', err)
        })
        project = context.state.projects[context.state.currentProjectId]
      }
      return Vue.$publicApi.get('/project/task/gettasks', {
        params: {
          projectid: project.projectId,
          charitysub: project.charitySub
        }
      }).then((response) => {
        const tasks = response.data
        // console.log(tasks)
        context.commit('setTasks', tasks)
        return tasks
      }).catch((err) => {
        console.error(err.response)
        throw err
      })
    },
    deleteCurrentProject (context) {
      const project = context.state.projects[context.state.currentProjectId]
      return Vue.$privateApi.put('/project/charity/delete', project).then((response) => {
        delete context.state.projects[project.projectId]
        // console.log(context.state.projects)
        return response
      }).catch((err) => {
        console.error(`This project ${project.title} cannot be deleted if at least 1 donation exists.`)
        throw err
      })
    },
    async createTask (context, task) {
      const project = context.state.projects[context.state.currentProjectId]
      task.projectId = project.projectId
      return Vue.$privateApi.post('/project/charity/task/create', task)
        .then((response) => {
          // console.log('success', response.data)
          return Vue.$publicApi.get('/account/searchusers', {
            params: {
              content: `id:${task.executor}`,
              group: '-'
            }
          }).then((accountResponse) => {
            const taskdata = {}
            taskdata.task = response.data
            taskdata.executor = accountResponse.data[0]
            context.commit('setTasks', [taskdata])
            context.commit('setAddedTask', taskdata)
            return response
          })
        }).catch((err) => {
          // console.log('error', err)
          throw err
        })
    },
    updateTask (context, task) {
      return Vue.$privateApi.put('/project/task/update', task.task).then((response) => {
        return response
      }).catch((err) => {
        throw err
      })
    },
    deleteTask (context, task) {
      return Vue.$privateApi.put('/project/charity/task/delete', task.task).then((response) => {
        // console.log('Successfully delete task')
        context.commit('setDeletedTask', task)
        return response
      }).catch((err) => {
        throw err
      })
    }
  }
}
