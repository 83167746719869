<template>
    <v-card v-if="state.isLoaded" heigh="100%" :width="$vuetify.breakpoint.mobile?'80vw':'50vw'"
        class="mx-auto" color="rgb(x,x,x,0.7)" rounded>
        <v-card v-if= state.isActive color="transparent" elevation="0">
            <v-sheet color="primary" height="15%" class="d-flex justify-center">
                <h1 class="white--text mt-15" >PWA</h1>
            </v-sheet>
            <v-card-title class="d-flex justify-center">
                <span class="text-center text-h5">{{pwaAppInfo.name}}</span>
            </v-card-title>
            <div class="mt-n4 mb-2 d-flex justify-space-around ">
            <v-chip-group multiple column>
                <v-chip v-for="(category,n) in charityOfApp.categories"
                :key="n" :color="colors[n]" label
                :class="$vuetify.breakpoint.mobile?'mx-auto':'mx-2'">
                  {{category}}</v-chip>
            </v-chip-group>
            </div>
            <v-divider class="mx-15 my-2" ></v-divider>
            <v-card-actions>
                <v-btn @click="installPwa" color= "primary" class=" mt-n2 mb-2 mx-auto">Install</v-btn>
            </v-card-actions>
            <v-divider class="mx-15" ></v-divider>
            <v-card-text>
                <v-card class="mx-auto" elevation="0" color="transparent" :width="$vuetify.breakpoint.mobile?'98%':'50%'">
                <v-carousel progress
                    hide-delimiter-background height="280">
                <v-carousel-item v-for="(photo,n) in pwaPhotos" :key="n" show-arrows-on-hover
                    :src="photo.src">
                </v-carousel-item>
                </v-carousel>
                </v-card>
                <v-divider class="mx-15 mt-5" ></v-divider>
                <v-card class="mx-auto" elevation="0" color="transparent"
                  :width="$vuetify.breakpoint.mobile?'90%':'50%'">
                    <v-card-text class="text-center text-subtitle-2">
                        {{pwaAppInfo.description}}
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title class="d-flex justify-center">
            <div class="text-center text-h5 text-wrapped">
                  We've temporarily closed the access to PWA
            </div>
          </v-card-title>
          <div class="text-center text-subtitle-1 text-wrapped my-3">Please contact us if you wish!</div>
          <v-card-subtitle class="primary--text d-flex justify-end mr-7">--{{barTitle}}--</v-card-subtitle>
        </v-card>
        <v-bottom-sheet v-model="forIosDevice">
          <v-card height="17vh">
            <v-card width="80%" class="mx-auto" color="transparent" elevation="0">
                <v-row justify="center" class="mt-12 text-center" >
                  You are using iOS Device but iOS still hasn't supported automatic installation!
                </v-row>
                <v-row justify="center" class="mb-16 text-center">
                  To install, please click Safari <kbd class="primary mx-2">Share Button</kbd> below
                    <v-icon color="primary">mdi-arrow-down</v-icon>
                    and <kbd class="primary mx-2">[Add to Home Page]</kbd> for Installation
                </v-row>
            </v-card>
          </v-card>
        </v-bottom-sheet>
    </v-card>
    <v-card v-else>
        <v-skeleton-loader type="card, list-item, actions"  class = "mx-auto my-3"
            max-height="80%" max-width="50%"></v-skeleton-loader>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// import Vue from 'vue'
export default {
  name: 'PwaDashboard',
  data () {
    return {
      state: {
        isLoaded: false,
        isActive: false
      },
      colors: ['pink lighten-1', 'teal darken-1', 'indigo lighten-1', 'deep-purple lighten-1',
        'light-green darken-1'],
      forIosDevice: false
    }
  },
  computed: {
    ...mapGetters('appStore', ['pwaAppInfo', 'pwaPhotos',
      'barTitle', 'charityOfApp'])
  },
  watch: {
    /**
     * in subsequent loading, the appinfo will be set to either null or assigned appinfo object data
     * If it is inactive, then it is set to null
     */
    pwaAppInfo (newValue) {
      console.log(`PWA Detials: new data is loaded: ${newValue}`)
      this.state.isLoaded = true
      if (this.pwaAppInfo.active) {
        this.state.isActive = true
        // console.log(this.pwaAppInfo)
      } else {
        this.state.isActive = false
      }
    }
  },
  methods: {
    async installPwa () {
      console.log('platform', navigator.platform)
      // iOS devices have not yet supported PWA Prompt
      if (['iPhone', 'iPad', 'iPod'].includes(navigator.platform)) {
        console.log('platform', navigator.platform)
        this.forIosDevice = true
      } else {
        window.installEvent.prompt()
        await window.installEvent.userChoice
      }
    }
  },
  /**
   * Determine initial state of loading the PWA and if the PWA's info is
   * activated by the Owner (charity user)
   */
  created () {
    // console.log(this.pwaAppInfo.active)
    if (this.pwaAppInfo && this.pwaAppInfo.active) {
      this.state.isLoaded = true
      this.state.isActive = true
    } else if (this.pwaAppInfo) {
      this.state.isLoaded = true
      this.state.isActive = false
    } else {
      this.state.isLoaded = false
      this.state.isActive = false
    }
    // console.log(this.pwaPhotos)
    // console.log(this.state.isLoaded, this.state.isActive)
  }
}
</script>
