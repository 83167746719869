import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    donations: {},
    temp: null,
    currentDonationId: null
  },
  mutations: {
    /**
     * Setting null and reassigning to trigger
     *  any watch function for this to refresh
     */
    cleanDonations (state) {
      state.temp = state.donations
      state.donations = null
      state.temp = {}
      state.donations = state.temp
      state.temp = null
    },
    setDonations (state, donations) {
      donations.forEach(donation => {
        state.temp = state.donations
        state.donations = null
        state.temp[donation.donationId] = donation
        state.donations = state.temp
        state.temp = null
      })
    },
    setCurrentDonationId (state, id) {
      state.currentDonationId = id
    },
    setTransactionsForDonation (state, data) {
      const transactions = data.transactions
      const donationId = data.donationId
      state.temp = state.donations
      state.donations = null
      state.temp[donationId].transactions = transactions
      state.donations = state.temp
      state.temp = null
      // console.log(state.donations)
    },
    addTransactionsForDonation (state, data) {
      const transaction = data.transaction
      const donationId = data.donationId
      state.temp = state.donations
      state.donations = null

      if (state.temp[donationId]) {
        if (state.temp[donationId].transactions) {
          state.temp[donationId].transactions.push(transaction)
        } else {
          state.temp[donationId].transactions = []
          state.temp[donationId].transactions.push(transaction)
        }
      } else {
        state.temp[donationId].transactions = [transaction]
      }
      state.donations = state.temp
      state.temp = null
    }
  },
  getters: {
    donations (state) {
      return state.donations
    },
    currentDonation (state) {
      if (state.currentDonationId) {
        console.log(state.currentDonationId, state.donations)
        if (state.donations !== null) {
          console.log('return donations!')
          return state.donations[state.currentDonationId]
        } else {
          console.log('return temp!')
          return state.temp[state.currentDonationId]
        }
      } else {
        console.log('return null!')
        return null
      }
    },
    currentTransactionsSet (state) {
      if (state.donations) {
        if (state.currentDonationId in state.donations &&
          'transactions' in state.donations[state.currentDonationId]) {
          return state.donations[state.currentDonationId].transactions
        }
      } else {
        if (state.currentDonationId in state.temp &&
          'transactions' in state.temp[state.currentDonationId]) {
          return state.temp[state.currentDonationId].transactions
        }
      }
    }
  },
  actions: {
    createDonation (context, donation) {
      const project = context.rootGetters['projectStore/currentProject']
      donation.projectId = project.projectId
      donation.live = true
      // console.log('donation', donation)
      Vue.$privateApi.post('/project/charity/donation/create', donation).then((response) => {
        context.commit('setDonations', [response.data])
        return response
      }).catch((err) => {
        throw err
      })
    },
    requestOwnDonations (context) {
      const project = context.rootGetters['projectStore/currentProject']
      // console.log(project.projectId)
      return Vue.$privateApi.get('/project/charity/donation', {
        params: {
          projectId: project.projectId
        }
      }).then((response) => {
        context.commit('setDonations', response.data)
        return response
      }).catch(err => {
        throw err
      })
    },
    requestDonations (context) {
      const project = context.rootGetters['projectStore/currentProject']
      return Vue.$publicApi.get('/project/donation', {
        params: {
          projectId: project.projectId,
          charitySub: project.charitySub
        }
      }).then((response) => {
        context.commit('setDonations', response.data)
        return response.data
      }).catch((err) => {
        throw err
      })
    },
    updateDonation (context, donation) {
      return Vue.$privateApi.put('/project/charity/donation/update', donation)
        .then((response) => {
          context.commit('setDonations', [donation])
          // console.log(donation)
          // console.log(context.state.donations)
          return response
        }).catch((err) => {
          throw err
        })
    },
    requestTransactions (context) {
      const currentDonation = context.getters.currentDonation
      return Vue.$publicApi.get('/project/donation/transactions', {
        params: {
          donationId: currentDonation.donationId
        }
      }).then((response) => {
        context.commit('setTransactionsForDonation', {
          transactions: response.data,
          donationId: currentDonation.donationId
        })
        return response
      }).catch(() => {
        throw context.getters.currentTransactionsSet
      })
    },
    performTransctionManually (context, transaction) {
      const transactionSent = {}
      transactionSent.amount = transaction.amount
      const currentDonation = context.getters.currentDonation
      transactionSent.donationId = currentDonation.donationId
      transactionSent.projectId = currentDonation.projectId
      transactionSent.charitySub = currentDonation.charitySub
      // console.log(transactionSent)
      return Vue.$privateApi.post('/project/charity/donation/transaction/manuallycreate',
        transactionSent).then((response) => {
        context.commit('addTransactionsForDonation', {
          transaction: response.data,
          donationId: currentDonation.donationId
        })
        return response
      }).catch((err) => {
        console.error(err)
        throw err
      })
    },
    performTransactionWithStripe (context, data) {
      const transactionSent = {}
      transactionSent.amount = data.transaction.amount
      const currentDonation = context.getters.currentDonation
      transactionSent.donationId = currentDonation.donationId
      transactionSent.projectId = currentDonation.projectId
      transactionSent.charitySub = currentDonation.charitySub

      const redirect = data.redirect
      const url = `/project/donation/transaction/perform?redirect=${redirect}`
      return Vue.$privateApi.post(url, transactionSent).then((response) => {
        const map = response.data
        const accid = map.accid
        const sessionid = map.sessionid
        const stripeClient = Vue.$stripe.generateStripeFor(accid)

        return stripeClient.redirectToCheckout({
          sessionId: sessionid
        })
      })
    }
  }
}
