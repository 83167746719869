<template>
  <v-navigation-drawer
    app
    temporary
    disable-route-watcher
    v-model="isDrawerOpen"
  >
    <AccBasicDisplay :user="user" :pictureSize = '40'></AccBasicDisplay>
    <v-divider></v-divider>
    <v-list float dense>
      <div v-for="view in views" :key="view.pathname">
        <v-list-item
          v-if="view.displayed"
          link
          @click="navigate(view.navFunc)"
        >
          <v-list-item-icon>
            <v-icon x-large>{{ view.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ view.title }}</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
    <template v-slot:append>
      <!-- set responsiveness of the div for mobile and desktop views -->
      <div class="mb-16 pb-16 mb-sm-16 pb-sm-16">
        <div>
          <v-list-item>
            <v-list-item-icon>
              <span v-if="darkTheme">
                <v-icon large>mdi-weather-night</v-icon>
              </span>
              <span v-else>
                <v-icon color="black" large>mdi-weather-sunny</v-icon>
              </span>
            </v-list-item-icon>
            <v-list-item-action>
              <v-switch v-model="darkTheme" inset color="black"></v-switch>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                Dark Mode
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-divider></v-divider>
        <div class="pa-2">
          <v-btn block @click="loginOrOut" class="primary">
            {{ loginText }}
          </v-btn>
        </div>
        <div v-if="loginText === 'Login'" class="pa-2">
          <div class="text-center">
            <span>OR</span>
          </div>
          <v-btn
            block
            text
            @click="registerPage"
            elevation="0"
            class="blue--text"
          >
            Register
          </v-btn>
        </div>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import Vue from 'vue'
import AccBasicDisplay from '@/components/account/AccBasicDisplay.vue'
export default {
  components: {
    AccBasicDisplay
  },
  props: ['isOpen'],
  data () {
    return {
      isDrawerOpen: false,
      darkTheme: '',
      /**
       * list of views to be navigated
       */
      views: [
        {
          title: 'Home',
          icon: 'mdi-home',
          displayed: true,
          navFunc: () => { this.commonNavigate('Home') }
        },
        {
          title: 'Account',
          icon: 'mdi-clipboard-account',
          displayed: Vue.$keycloak.authenticated,
          navFunc: () => { this.commonNavigate('AccountView') }
        },
        {
          title: 'Contributions',
          icon: 'mdi-star-face',
          displayed:
            Vue.$keycloak.authenticated &&
            Vue.$keycloak.tokenParsed.groups[0].includes('giver'),
          navFunc: () => {
            this.idNavigate('ContributionView', { giver: this.user.email })
          }
        },
        {
          title: 'Projects',
          icon: 'mdi-account-multiple',
          displayed: true,
          navFunc: () => { this.commonNavigate('ProjectsView') }
        }
      ]
    }
  },
  methods: {
    /**
     * redirect to login or logout page depending on current login status.
     * If a login page is loaded, then it will be opened in a new tab and when an auth event is received,
     *  it should close the child window tab and reload itself to refresh the login status.
     */
    loginOrOut () {
      if (Vue.$keycloak.authenticated) {
        const redirect = window.location.href.replace(
          window.location.pathname,
          `/${Vue.$pathConverter.getFirstSegment()}/`
        )
        Vue.$keycloak.logout({ redirectUri: redirect })
      } else {
        const uri = Vue.$keycloak.createLoginUrl({
          redirectUri: window.location.href
        })
        // open a new window for login
        const pop = window.open(uri)
        window.addEventListener('message', (e) => {
          if (e.data.auth === true) {
            pop.close()
            location.reload()
          }
        })
      }
    },
    /**
     * navigate to register page for user registration */
    registerPage () {
      const navFunc = () => {
        this.commonNavigate('RegisterView')
      }
      this.navigate(navFunc)
    },
    navigate (navFunc) {
      navFunc()
    },
    /**
     * a general navigation method that set the first segment of the path to the current one.
     * E.g. /iocharity/home to /iocharity/account
     */
    commonNavigate (componentName) {
      const firstSeg = Vue.$pathConverter.getFirstSegment()
      const params = { root: firstSeg }
      this.$router.push({
        name: componentName,
        params: params
      })
    },
    idNavigate (componentName, id) {
      const firstSeg = Vue.$pathConverter.getFirstSegment()
      const root = { root: firstSeg }
      const params = {
        ...root, ...id
      }
      this.$router.push({
        name: componentName,
        params: params
      })
    }
  },
  computed: {
    user: function () {
      if (Vue.$keycloak.authenticated) {
        return Vue.$keycloak.tokenParsed
      } else {
        return {}
      }
    },
    loginText: function () {
      if (Vue.$keycloak.authenticated) {
        return 'Logout'
      } else {
        return 'Login'
      }
    }
  },
  watch: {
    /**
     * update and cache the user preference theme when user has picked the preferred theme
     */
    darkTheme: function (newValue) {
      this.$vuetify.theme.dark = newValue
      localStorage.setItem('darkTheme', JSON.stringify(newValue))
    },
    /* update isDrawerOpen when isOpen prop is updated */
    isOpen (newValue) {
      this.isDrawerOpen = newValue
    },
    /**
     * when isDrawerOpen is updated, send an update event to the parent component
     * with isOpen watch function, this allows 2-way binding without using Vuex since this only involves a single state
     */
    isDrawerOpen (newValue) {
      this.$emit('update:isOpen', newValue)
    }
  },
  created () {
    /**
     * set theme on created, if no theme is found, it will be in default light theme
     */
    const darkTheme = JSON.parse(localStorage.getItem('darkTheme'))
    if (darkTheme) {
      this.themeIcon = 'mdi-weather-night'
    } else {
      this.themeIcon = 'mdi-weather-sunny'
    }
    this.darkTheme = darkTheme
    this.$vuetify.theme.dark = darkTheme
  }
}
</script>
