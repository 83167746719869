import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    charities: {},
    lastSearch: [],
    searchHistory: new Set(),
    searchedCategories: new Set()
  },
  getters: {
    charities (state) {
      return state.charities
    },
    lastSearch (state) {
      return state.lastSearch
    }
  },
  mutations: {
    addSearchHistory (state, searchString) {
      state.searchHistory.add(searchString)
    },
    addCharities (state, charities) {
      charities.forEach(charity => {
        state.charities[charity.email] = charity
      })
    }
  },
  actions: {
    /* return the charities from backend API
     * This function will cache the search history in a Set whenever the response is 200OK.
     * If the input is found inside the search history of content and category,
     * no backend search request is sent to avoid flooding the backend services
     */
    searchCharities (context, input) {
      const content = input.content
      const category = input.category
      if (content) {
        if (category &&
            !context.state.searchedCategories.has(category)) {
          return Vue.$publicApi.get('/account/searchcharities/category',
            {
              params: {
                content: content, category: category
              }
            })
            .then(response => {
              context.commit('addCharities', response.data)
              context.commit('addSearchHistory', content)
              context.state.lastSearch = response.data
              context.state.searchedCategories.add(category)
              return response
            }).catch((err) => {
              throw Error(err)
            })
        } else if (!context.state.searchHistory.has(content)) {
          return Vue.$publicApi.get('/account/searchusers',
            {
              params: { content: content, group: 'charity' }
            })
            .then(response => {
              // console.log(response.data)
              context.commit('addCharities', response.data)
              context.commit('addSearchHistory', content)
              context.state.lastSearch = response.data
              return response
            }).catch((err) => {
              throw Error(err)
            })
        }
        return true
        // else, no search needed since everything is cached,
        // caches in charitystore are retreived drectly via getter or getCharityByEmail()
        // in case of autocomplete component for search bar, each search result is cached by the component itself
        //  and current search result is retreived through getter for lastSearch state
      }
    },
    getCharityByEmail (context, charityEmail) {
      return context.state.charities[charityEmail]
    },
    containsCharity (context, charity) {
      return charity in context.state.charities
    }
  }
}
