import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import pwaconfig from '@/pwaconfig'

/**
 * Async loading components to avoid delaying the rendering
 */
const SearchView = () => import('@/views/SearchView.vue')
const RegisterView = () => import('@/views/RegisterView.vue')
const AccountView = () => import('@/views/AccountView.vue')
const CharityAppHomeView = () => import('@/views/CharityAppHomeView.vue')
const CharityView = () => import('@/views/CharityView.vue')
const ContributionView = () => import('@/views/ContributionView.vue')
const PwaSettingView = () => import('@/views/PwaSettingView.vue')
const ProjectsView = () => import('@/views/ProjectsView.vue')
const ProjectDetailsView = () => import('@/views/ProjectDetailsView.vue')
const ErrorView = () => import('@/views/ErrorView.vue')
Vue.use(VueRouter)
/**
 * The design of the navigation involves spitting the web app into 2 groups for implementing multi-PWAs.
 *  1. iocharity: uses /iocharity/**, users at this url can install the PWA of iocharity or goes to any /:charity/** to install PWA
 *  2. any charity: uses /:charity/**. users at this url either access via browser or the PWA of the charity
 *      being put in the placeholder :charity,
 *      but cannot install PWAs of any other charities
 *  The first segment of the path (e.g. iocharity of '/iocharity/**) will determine the current PWA
 */
const routes = [
  // the UI for iocharity
  {
    path: '/iocharity',
    name: 'IocharityView',
    component: SearchView
  },
  // redirec root to /Main
  {
    path: '/',
    redirect: '/iocharity'
  },
  // the UI for any other charities. The validity and exception of the charity is checked when the page is loaded
  {
    path: '/:root',
    name: 'CharityAppHomeView',
    component: CharityAppHomeView
  },
  // redirect to a charity UI, CharityView, or the Main UI, IocharityView, depending on the param inputted,
  //  which depends on the first segment of the current page. This is used when Home at menu is chosen by user.
  {
    path: '/:root',
    name: 'Home',
    redirect: '/:root'
  },
  // search UI for either charity or iocharity
  {
    path: '/:root/search',
    name: 'SearchView',
    component: SearchView
  },
  // registration UI for either charity or iocharity
  {
    path: '/:root/register',
    name: 'RegisterView',
    component: RegisterView
  },
  // current user account
  {
    path: '/:root/account',
    name: 'AccountView',
    component: AccountView
  },
  {
    path: '/:root/account/pwa',
    name: 'PwaSettingView',
    component: PwaSettingView
  },
  {
    path: '/:root/charity@/:charity',
    name: 'CharityView',
    component: CharityView
  },
  {
    path: '/:root/giver@/:giver',
    name: 'ContributionView',
    component: ContributionView
  },
  {
    path: '/:root/projects',
    name: 'ProjectsView',
    component: ProjectsView
  },
  {
    path: '/:root/projects/:projectId',
    name: 'ProjectDetailsView',
    component: ProjectDetailsView
  },
  // will be redirected to this page when the page has nothing to displat as no url is found or no access right is granted
  {
    path: '/:root/error',
    name: 'Error',
    component: ErrorView
  },
  {
    path: '/**',
    name: 'others',
    redirect: () => {
      const segment = Vue.$pathConverter.getFirstSegment()
      if (segment) {
        window.location.href = `/${segment}/error`
      } else {
        window.location.href = '/iocharity/error'
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * A function, which is trigged before every route occurs
 * This function checks the destination and compare with the source.
 * When the first segment of destination (e.g. charity@charity.com of /charity@charity.com/projects)
 *   is found to be different from the one of the source or belongs to the default iocharity,
 *   then the charity to be stored in the Vuex appStore will be updated.
 */
router.beforeEach((to, from, proceed) => {
  // console.log(' FROM: ', from.fullPath, 'Go TO:', to.fullPath)
  const destinationSegment = Vue.$pathConverter.getFirstSegmentOf(to.fullPath)
  const sourceSegment = Vue.$pathConverter.getFirstSegmentOf(from.fullPath)
  if (destinationSegment === sourceSegment) {
    proceed()
  } else if (destinationSegment === 'iocharity' || destinationSegment === '') {
    store.dispatch('appStore/initCharityOfTheApp', 'iocharity').then(() => {
      // console.log('->tab title iocharity')
      Vue.$pathConverter.convertTabTitle()
    })
    store.dispatch('appStore/initPwaDetails', 'iocharity').then(() => {
      // console.log('init pwa done')
      Vue.$pathConverter.convertFavicon()
    })
    proceed()
  } else {
    store.dispatch('appStore/initCharityOfTheApp', destinationSegment).then(() => {
      Vue.$pathConverter.convertTabTitle()
    }).catch((err) => {
      console.log('redirect: ', err)
      const toSegment = Vue.$pathConverter.getFirstSegmentOf(to.fullPath)
      proceed(`/${toSegment}/error`)
    })
    store.dispatch('appStore/initPwaDetails', destinationSegment).then(() => {
      Vue.$pathConverter.convertFavicon()
    })
  }
  proceed()
})

/**
 * To avoid manifest from being loaded before a redirect happens.
 * Hence, it is loaded after the destination is confirmed
 */
router.afterEach((to, from) => {
  const toSegment = Vue.$pathConverter.getFirstSegmentOf(to.fullPath)
  const fromSegment = Vue.$pathConverter.getFirstSegmentOf(from.fullPath)
  if (toSegment !== fromSegment) {
    // console.log('Update PWA manifest')
    pwaconfig.registerPWAAndManifest()
  }
})

export default router
